import { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./newNav.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "google-fonts"; // Import the google-fonts package

function NewNav() {
  const [expanded, setExpanded] = useState(false);
  const [isSpecialPage, setIsSpecialPage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Check if the current URL matches a specific path
    setIsSpecialPage(location.pathname === "/qhse" || location.pathname === "/services" || location.pathname === "/career" || location.pathname === "/Admin/panel");
  }, [location.pathname]);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={isSpecialPage ? "" : "bg-body-tertiary transparent-navbar"}
      expanded={expanded}
    >
      <Container>
        <Navbar.Brand href="/" className=" font-weight-bold">
          <img
            src="/Branding/bes%20logo%20(1)-1-200h.png"
            width="170"
            height="70"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          
            <Nav.Link href="/" className=" font-weight-bold">
              Home
            </Nav.Link>
            <Nav.Link href="/projects" className=" font-weight-bold">
              Projects
            </Nav.Link>
            <Nav.Link href="/services" className="font-weight-bold ">
              Services
            </Nav.Link>
            <Nav.Link href="/clients" className="font-weight-bold ">
              Clients
            </Nav.Link>
            <Nav.Link href="/qhse" className="font-weight-bold ">
              QHSE
            </Nav.Link>
            <Nav.Link href="/career" className="font-weight-bold ">
              Career
            </Nav.Link>
          </Nav>
          <Nav className="gap-4">
            <Nav.Link href="tel:+968-71160030" className=" d-flex mt-2">
              <img
                alt="image"
                src="/Icons/phone.svg"
                className="home-image07"
              />
              <span className="phone">+968-71160030</span>
            </Nav.Link>
            <Nav.Link eventKey={2} href="/#book" className="button-main px-3">
              <svg viewBox="0 0 1024 1024" class="navbar-icon">
                <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM256 256h512c9.138 0 18.004 1.962 26.144 5.662l-282.144 329.168-282.144-329.17c8.14-3.696 17.006-5.66 26.144-5.66zM192 704v-384c0-1.34 0.056-2.672 0.14-4l187.664 218.942-185.598 185.598c-1.444-5.336-2.206-10.886-2.206-16.54zM768 768h-512c-5.654 0-11.202-0.762-16.54-2.208l182.118-182.118 90.422 105.498 90.424-105.494 182.116 182.12c-5.34 1.44-10.886 2.202-16.54 2.202zM832 704c0 5.654-0.762 11.2-2.206 16.54l-185.6-185.598 187.666-218.942c0.084 1.328 0.14 2.66 0.14 4v384z"></path>
              </svg>
              <span class="navbar-text5 ">
                <span>Reach out to us</span>
              </span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NewNav;
