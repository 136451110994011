import React from "react";

import PropTypes from "prop-types";

import "./book.css";

import { useState, useRef, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";

const Book = (props) => {
  const initialValue = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialValue);

  const form = useRef();
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  // for handiling submition
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData.contact);
    if (
      formData.phone === "" ||
      formData.email === "" ||
      formData.name === ""
    ) {
      toast.error("Invalid Details");
    } else {
      emailjs
        .sendForm(
          "service_dqtqxpg",
          "template_lm6zl1r",
          form.current,
          "GubnUHFWEcjCFlylY",
          formData
        )
        .then(
          (result) => {
            setFormData({
              name: "",
              email: "",
              phone: "",
              message: "",
            });
            toast("Success");
            console.log(result.text);
          },

          (error) => {
            toast("Error Occured");
            console.log(error.text);
          }
        );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section id="book" className={`book-book ${props.rootClassName} `}>
      <ToastContainer />

      <div className="book-heading">
        <h2 className="book-text">{props.heading}</h2>
        <p className="book-text1">
          <span className="">
            Kindly fill the form or reach out to us through(
          </span>
          <a href="tel:+968-71160030" className="book-link">
            +968-71160030
          </a>
          <span className="">
            {" "}
            or
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <a href="mailto:contact@sesmuscat.com?subject=" className="book-link1">
            contact@sesmuscat.com
          </a>
          <span className="">)</span>
        </p>
      </div>
      <div className="book-form">
        <div className="book-types"></div>
        <form ref={form} onSubmit={handleSubmit} className="book-inputs">
          <input
            type="text"
            name="name"
            placeholder={props.textinputPlaceholder}
            autoComplete="name"
            className="input book-input"
            onChange={onChange}
            value={formData.name}
          />
          <input
            type="email"
            name="email"
            placeholder={props.textinputPlaceholder1}
            autoComplete="email"
            className="input book-input"
            onChange={onChange}
            value={formData.email}
          />
          <input
            type="tel"
            name="phone"
            placeholder={props.textinputPlaceholder2}
            autoComplete="tel"
            className="input book-input"
            onChange={onChange}
            value={formData.phone}
          />
          <input
            name="message"
            type="text"
            placeholder={props.textinputPlaceholder3}
            className="input book-input"
            onChange={onChange}
            value={formData.message}
          />
          <div className="book-lower">
            <div className="book-button">
              <button className="book-book1 button button-main">
                <span className="">{props.text1}</span>
              </button>
              <p className="book-text6">{props.text}</p>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

Book.defaultProps = {
  rootClassName: "",
  text: "Complete this form with your contact details and service need or project scope. A Saffron Electrical Engineering Services LLC expert will be in touch shortly to provide guidance and next steps. We are committed to ensuring every customer inquiry receives a prompt response.",
  textinputPlaceholder2: "Phone",
  textinputPlaceholder3: "Message if any",
  heading: "Reach out to us",
  textinputPlaceholder1: "Email",
  text1: "Book",
  textinputPlaceholder: "Name",
};

Book.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
  textinputPlaceholder2: PropTypes.string,
  textinputPlaceholder3: PropTypes.string,
  heading: PropTypes.string,
  textinputPlaceholder1: PropTypes.string,
  text1: PropTypes.string,
  textinputPlaceholder: PropTypes.string,
};

export default Book;
