import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet-async";


import Navbar from "../components/navbar";
import Book from "../components/book";
import News from "../components/news";
import Footer from "../components/footer";
import "./home.css";
import OngoingWorks from "./ongoing-works";
import Form from "./form"

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Saffron Electrical Engineering Services LLC</title>
        <meta
          name="description"
          content="Saffron Electrical Engineering Services LLC in Oman excels in electrical  projects. As a C Grade Contractor, we prioritize high standards for both government and private contracts."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      
      <section className="home-hero">
        {/* <Navbar rootClassName="navbar-root-class-name"></Navbar> */}
        <div className="home-main">
          <div className="home-content">
            <div className="home-heading07">
              <h1 className="home-header07">
                Building a Brighter Oman Through Electrical Excellence
              </h1>
              <p className="home-caption06">
                <span className="home-text006">
                  Saffron Electrical Engineering Services LLC
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span>
                  in Oman excels in electrical and civil projects, specializing
                  in underground cables, substations, and high-rise buildings.
                  As a C Grade Contractor, we prioritize high standards for
                  both government and private contracts.
                </span>
              </p>
            </div>
            <button className="button button-main home-book">
              <svg viewBox="0 0 1024 1024" className="home-icon1">
                <path d="M512 0c-282.77 0-512 229.23-512 512s229.23 512 512 512 512-229.23 512-512-229.23-512-512-512zM256 256h512c9.138 0 18.004 1.962 26.144 5.662l-282.144 329.168-282.144-329.17c8.14-3.696 17.006-5.66 26.144-5.66zM192 704v-384c0-1.34 0.056-2.672 0.14-4l187.664 218.942-185.598 185.598c-1.444-5.336-2.206-10.886-2.206-16.54zM768 768h-512c-5.654 0-11.202-0.762-16.54-2.208l182.118-182.118 90.422 105.498 90.424-105.494 182.116 182.12c-5.34 1.44-10.886 2.202-16.54 2.202zM832 704c0 5.654-0.762 11.2-2.206 16.54l-185.6-185.598 187.666-218.942c0.084 1.328 0.14 2.66 0.14 4v384z"></path>
              </svg>
              <span>
                <a href="#book" style={{ textDecoration: "none" }}>
                  <span className="text-light ">Reach out to us</span>
                </a>
                <br></br>
              </span>
            </button>
          </div>
          <div className="home-image06">
            <img
              alt="image"
              src="/News/untitled%20design%20(5)%20(1)-1500w-1500w.png"
              className="home-image07"
            />
          </div>
        </div>
        <div id="features" className="home-features">
          <div className="home-content1">
            <div className="home-section06 quick-links">
              <div className="home-heading08">
                <h3 className="home-header08">Quality Commitment</h3>
                <img
                  alt="image"
                  src="/Icons/arrow.svg"
                  className="home-icon3"
                />
              </div>
              <p className="home-text011">
                Saffron Electrical Engineering Services LLC is dedicated to satisfying client
                needs and continually improving our services through superior
                customer service and experience that ensures complete client
                satisfaction. We have implemented robust quality management
                processes to monitor objectives and drive ongoing improvements.
              </p>
              <div className="home-divider"></div>
            </div>
            <div className="home-section07 quick-links">
              <div className="home-heading09">
                <h3 className="home-header09">Mission</h3>
                <img
                  alt="image"
                  src="/Icons/arrow.svg"
                  className="home-icon4"
                />
              </div>
              <p className="home-text012">
                <span>
                  Our mission is to strengthen our presence in the
                  country&apos;s infrastructure development. We are committed to
                  achieving significant growth in alignment with the rapid
                  economic development. Additionally, we seek to establish
                  strategic alliances and engage in joint ventures with global
                  leaders.
                </span>
                <br></br>
                <br></br>
              </p>
              <div className="home-divider1"></div>
            </div>
            <div className="home-section08 quick-links">
              <div className="home-heading10">
                <h3 className="home-header10">Objectives</h3>
                <img
                  alt="image"
                  src="/Icons/arrow.svg"
                  className="home-icon5"
                />
              </div>
              <p className="home-text016">
                <span className="home-text017">
                  Our company is fully devoted to achieving its quality
                  objectives, focusing on initiating corrective and preventive
                  actions for continuous improvement. We aim to consistently
                  meet specified requirements to provide superior customer
                  service.
                </span>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
              </p>
              <div className="home-divider2"></div>
            </div>
          </div>
        </div>
        <div className="home-background"></div>
      </section>
      <section className="home-practices1">
        <div className="home-heading11">
          <h2 className="home-text022">Why Choose Us</h2>
          <p className="home-text023">
            Why Choose Saffron Electrical Engineering Services LLC?
          </p>
        </div>
        <div className="home-content2">
          <div className="home-grid1">
            <div to="/">
              <div className="home-practice-wrapper">
                <div className="home-practice">
                  <div className="home-heading12">
                    <h3 className="home-header11">Experienced Founders</h3>
                    <p className="home-caption07">
                      <span className="home-text024">
                        Leaders with 10+ years in electrical projects in Oman
                      </span>
                      <br className="home-text025"></br>
                      <br className="home-text026"></br>
                      <span className="home-text027">
                        Deep technical expertise in all electrical systems
                      </span>
                      <br className="home-text028"></br>
                      <br className="home-text029"></br>
                      <span className="home-text030">
                        Strong relationships with suppliers and authorities
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div to="/">
              <div className="home-practice-wrapper1">
                <div className="home-practice1">
                  <div className="home-heading13">
                    <h3 className="home-header12">Commitment to Quality</h3>
                    <p className="home-caption08">
                      <span className="home-text031">
                        Dedicated to meeting ISO and safety standards
                      </span>
                      <br className="home-text032"></br>
                      <br className="home-text033"></br>
                      <span className="home-text034">
                        Focused on continuous improvement
                      </span>
                      <br className="home-text035"></br>
                      <br className="home-text036"></br>
                      <span className="home-text037">
                        Vision to execute innovative, sustainable solutions
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div to="/">
              <div className="home-practice-wrapper2">
                <div className="home-practice2">
                  <div className="home-heading14">
                    <h3 className="home-header13">
                      Local Knowledge &amp; Tailored Solutions
                    </h3>
                    <p className="home-caption09">
                      <span className="home-text038">
                        In-depth understanding of Oman electrical code
                      </span>
                      <br className="home-text039"></br>
                      <br className="home-text040"></br>
                      <span className="home-text041">
                        Design solutions based on local conditions
                      </span>
                      <br className="home-text042"></br>
                      <br className="home-text043"></br>
                      <span className="home-text044">
                        Flexible customized packages for each client
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div to="/">
              <div className="home-practice-wrapper3">
                <div className="home-practice3">
                  <div className="home-heading15">
                    <h3 className="home-header14">
                      Reliable &amp; Responsive Service
                    </h3>
                    <p className="home-caption10">
                      <span className="home-text045">
                        Strong track record delivering complex projects on-time
                      </span>
                      <br className="home-text046"></br>
                      <br className="home-text047"></br>
                      <span className="home-text048">
                        Friendly, expert consultants accessible 24/7
                      </span>
                      <br className="home-text049"></br>
                      <br className="home-text050"></br>
                      <span className="home-text051">
                        Rapid emergency maintenance and service
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div to="/">
              <div className="home-practice-wrapper4">
                <div className="home-practice4">
                  <div className="home-heading16">
                    <h3 className="home-header15">Growth Focused Company</h3>
                    <p className="home-caption11">
                      <span className="home-text052">
                        Created to support Oman&apos;s 2040 vision
                      </span>
                      <br className="home-text053"></br>
                      <br className="home-text054"></br>
                      <span className="home-text055">
                        Built with a scalable structure eyeing expansion
                      </span>
                      <br className="home-text056"></br>
                      <br className="home-text057"></br>
                      <span className="home-text058">
                        Plan to be leading electrical partner in Oman
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="home-features1">
        <div className="home-section09">
          <div className="home-content3">
            <div className="home-header16">
              <h2 className="home-heading17">to be filled</h2>
              <p className="home-capton">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt.
              </p>
            </div>
            <div className="read-more">
              <span className="home-text059">See our fa</span>
              <img
                alt="image"
                src="/Icons/arrow-2.svg"
                className="home-image08"
              />
            </div>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1621905251918-48416bd8575a?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDZ8fGVsZWN0cmljYWwlMjBlbmdpbmVlcnxlbnwwfHx8fDE3MDU2NDM0MTJ8MA&amp;ixlib=rb-4.0.3&amp;h=2000"
            className="home-image09"
          />
        </div>
        <div className="home-section10">
          <div className="home-content4">
            <div className="home-header17">
              <h2 className="home-heading18">to be filled</h2>
              <p className="home-capton1">
                
              </p>
            </div>
            <div className="read-more">
              <span className="home-text060">Find test</span>
              <img
                alt="image"
                src="/Icons/arrow-2.svg"
                className="home-image10"
              />
            </div>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1581092157699-83c90752400a?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDh8fGVsZWN0cmljYWwlMjBlbmdpbmVlcnxlbnwwfHx8fDE3MDU2NDM0MTJ8MA&amp;ixlib=rb-4.0.3&amp;w=1500"
            className="home-image11"
          />
        </div>
        <div className="home-section11">
          <div className="home-content5">
            <div className="home-header18">
              <h2 className="home-heading19">to be filled</h2>
              <p className="home-capton2">
                
              </p>
            </div>
            <button className="button button-main home-book1">
              <span>Reach out to us</span>
            </button>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1581091226033-d5c48150dbaa?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDExfHxlbGVjdHJpY2FsJTIwZW5naW5lZXJ8ZW58MHx8fHwxNzA1NjQzNDEyfDA&amp;ixlib=rb-4.0.3&amp;h=2000"
            className="home-image12"
          />
        </div>
        <button className="home-book2 button button-main">
          <span>Book a virtual appointment</span>
        </button>
      </section>
      <section id="schedule" className="home-schedule">
        <div className="home-content6">
          <div className="home-header19">
            <h2 className="home-heading20">
              Schedule an in person or virtual appointment today
            </h2>
            <p className="home-caption12">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam.
            </p>
          </div>
          <div className="home-types">
            <a
              href="#book"
              className="home-book-person button button-main button-white"
            >
              <span>Book in person appointment</span>
            </a>
            <button className="button button-main button-white home-book-person1">
              <span>Book virtual appointment</span>
            </button>
          </div>
        </div>
      </section>
      <div className="home-search">
        
        <div className="home-content7">
          <div className="home-type-one">
            <div className="home-alphabet">
              <div data-letter="a" className="letter">
                <span className="home-text067">A</span>
              </div>
              <div data-letter="b" className="letter">
                <span className="home-text068">B</span>
              </div>
              <div data-letter="c" className="letter">
                <span className="home-text069">C</span>
              </div>
              <div data-letter="d" className="letter">
                <span className="home-text070">D</span>
              </div>
              <div data-letter="e" className="letter">
                <span className="home-text071">E</span>
              </div>
              <div data-letter="f" className="letter">
                <span className="home-text072">F</span>
              </div>
              <div data-letter="g" className="letter">
                <span className="home-text073">G</span>
              </div>
              <div data-letter="h" className="letter">
                <span className="home-text074">H</span>
              </div>
              <div data-letter="i" className="letter">
                <span className="home-text075">I</span>
              </div>
              <div data-letter="j" className="letter">
                <span className="home-text076">J</span>
              </div>
              <div data-letter="k" className="letter">
                <span className="home-text077">K</span>
              </div>
              <div data-letter="l" className="letter">
                <span className="home-text078">L</span>
              </div>
              <div data-letter="m" className="letter">
                <span className="home-text079">M</span>
              </div>
              <div data-letter="n" className="letter">
                <span className="home-text080">N</span>
              </div>
              <div data-letter="o" className="letter">
                <span className="home-text081">O</span>
              </div>
              <div data-letter="p" className="letter">
                <span className="home-text082">P</span>
              </div>
              <div data-letter="q" className="letter">
                <span className="home-text083">Q</span>
              </div>
              <div data-letter="r" className="letter">
                <span className="home-text084">R</span>
              </div>
              <div data-letter="s" className="letter">
                <span className="home-text085">S</span>
              </div>
              <div data-letter="t" className="letter">
                <span className="home-text086">T</span>
              </div>
              <div data-letter="u" className="letter">
                <span className="home-text087">U</span>
              </div>
              <div data-letter="v" className="letter">
                <span className="home-text088">V</span>
              </div>
              <div data-letter="w" className="letter">
                <span className="home-text089">W</span>
              </div>
              <div data-letter="x" className="letter">
                <span className="home-text090">X</span>
              </div>
              <div data-letter="y" className="letter">
                <span className="home-text091">Y</span>
              </div>
              <div data-letter="z" className="letter">
                <span className="home-text092">Z</span>
              </div>
            </div>
            
          </div>

        </div>
      </div>
      <Book></Book>
      <Form />
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  );
};

export default Home;
