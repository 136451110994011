import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

import "./style.css";
import Clients from "./views/clients";
import Projects from "./views/projects";
import OngoingWorks from "./views/ongoing-works";
import Home from "./views/home";
import Services from "./views/services";
import ProjectDetails from "./views/project-details";
import NotFound from "./views/not-found";
import NewNav from "./views/newNav";
import Navbar from "./components/navbar";
import Career from "./components/career";
import Qhse from "./components/qhse";
// Admin interface
import Login from "./Admin/Login";
import AdminMain from "./Admin/AdminMain";

import { AuthProvider } from "./context/Context";
import { HelmetProvider } from "react-helmet-async";
const App = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <NewNav />

      <Switch>
        <AuthProvider>
          <Route component={Clients} exact path="/clients" />
          <Route component={Projects} exact path="/projects" />
          <Route component={Qhse} exact path="/qhse" />
  
          {/* <Route component={OngoingWorks} exact path="/ongoing-works" /> */}
          <Route component={Career} exact path="/career" />
          <Route component={Home} exact path="/" />
          <Route component={Services} exact path="/services" />
          <Route component={ProjectDetails} exact path="/project-details/:id" />

          <Route component={Login} exact path="/Admin/Login" />
          <Route component={AdminMain} exact path="/Admin/panel" />
        </AuthProvider>

        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
  document.getElementById("app")
);
