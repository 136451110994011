import React from 'react'
import { Helmet } from 'react-helmet'
import './form.css'

const Form = (props) => {
  return (
    <div>
      <Helmet>
        <title>Saffron Electrical Engineering Services LLC</title>
        <meta property="og:title" content="Form - besoman" />
      </Helmet>
      <div className="container">
      <p className='display-5 pb-2 ' >Meet our team</p>

        <section className="team">
          <div className="team-header">
            <div className="leader">
              <img src="leader.png" alt="Anu Kinaruvila Balachandran"/>
              <div className="quote">
                <blockquote>
                  "At SESMUSCAT-Saffron Electrical Engineering Services LLC, we power progress with precision and elevate standards in every project, from underground cables to high-rise marvels."
                </blockquote>
                <p className="leader-name">ANU BALACHANDRAN UNNITHAN</p>
                <p className="leader-title">Managing Director and CEO</p>
              </div>
            </div>
          </div>
          <div className="board-members">
            <div className="member">
              <img src="member2.png" alt="Vinod Parapurath Vijayan"/>
              <p className="member-name">VINOD PARAPURATH VIJAYAN</p>
              <p className="member-title">Board Member</p>
            </div>
            <div className="member">
              <img src="member1.png" alt="Ahmed Nasser Fadhil Al Yahyaey"/>
              <p className="member-name">AHMED NASSER FADHIL AL YAHYAEY</p>
              <p className="member-title">Board Member</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Form
