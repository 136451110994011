import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";
import Image from "react-bootstrap/Image";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Certificate from "./certificates";
import "./career.css";

const Qhse = () => {
  const pageStyle = {
    fontFamily: "'Poppins', sans-serif",
  };
  return (
    <div style={pageStyle} className="career-container">
      <Helmet>
        <title>QHSE - SESMuscat</title>
        <meta property="og:title" name="description" content="Learn about SES Muscat LLC's commitment to Quality, Health, Safety, and Environment in all our electrical solutions." />
      </Helmet>
      <div className="career-container">
        <div className="hero2-header5 thq-section-padding">
          <img
            alt="image"
            src="https://www.ggf.org.uk/wp-content/uploads/2023/08/Health-safety-scaled.jpeg"
            className="hero2-image"
          />
          <div className="hero2-container">
            <div className="hero2-max-width thq-section-max-width">
              <div className="hero2-column">
                <div className="hero2-content">
                  <h1 className="hero2-text thq-heading-1">
                    QHSE
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="qhse-heading">
                    QHSE
                  </h1>
        <p className="hero2-qhse">
             Saffron Electrical Engineering Services LLC is dedicated to the concept that all accidents are preventable and philosophically unacceptable. Accordingly, all employees are committed to a “Zero Accident Philosophy”. All company activities will be conducted in such a way that the Health, Safety & Environment of SES employees and other persons associated with the company is a first priority. The policy of Saffron Electrical Engineering Services LLC is to manage Quality, Engineering and Productivity with the best interests of our Client, Employees and General Public in mind. SES maintains a firm commitment to provide our Client on time, cost effective engineering, procurement and construction services that reflect uncompromising quality and a commitment to continuous process improvement through the implementation of our Quality Management System.
        </p>
        <div>
        </div>
      </div>
      <Certificate />
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  );
};

export default Qhse;
